import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import SimplePage from "../components/simple-page";
import Paragraph from "../components/typography/paragraph";

const SuccessPage = () => {
  const data = useStaticQuery(graphql`
    query {
      success: file(relativePath: { eq: "success.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Success!" />
      <SimplePage
        title="Thank you!"
        description="Your order has been placed successfully."
        fluid={data.success.childImageSharp.fluid}
      >
        <Paragraph>
          You will receive an email with the details of your order in a few
          minutes. Thank you for supporting our business!
        </Paragraph>
      </SimplePage>
    </Layout>
  );
};

export default SuccessPage;
